<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>

            <!-- <el-menu-item index="/smartpublicguard/oldrun" @click="saveNavState('/smartpublicguard/oldrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">老年人健康管理</span>
              </template>
            </el-menu-item> -->

            <el-submenu index="jmjkda">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">居民健康档案</span>
              </template>
              <el-menu-item index="/smartpublicguard/oldrun" @click="saveNavState('/smartpublicguard/oldrun')">
                <template slot="title">
                  <span class="mrgin-left">健康档案管理</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <!-- <el-menu-item index="/smartpublicguard/healthtest" @click="saveNavState('/smartpublicguard/healthtest')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">健康体检</span>
              </template>
            </el-menu-item> -->

            <el-submenu index="jktjgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康体检管理</span>
              </template>
              <el-menu-item index="/smartpublicguard/healthtestrun/healthtest"
                @click="saveNavState('/smartpublicguard/healthtestrun/healthtest')">
                <template slot="title">
                  <span class="mrgin-left">体检管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/healthtestrun/healthreport"
                @click="saveNavState('/smartpublicguard/healthtestrun/healthreport')">
                <template slot="title">
                  <span class="mrgin-left">体检报告</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/healthtestrun/dataupload"
                @click="saveNavState('/smartpublicguard/healthtestrun/dataupload')">
                <template slot="title">
                  <span class="mrgin-left">数据上传</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/healthtestrun/progressdashboard"
                @click="saveNavState('/smartpublicguard/healthtestrun/progressdashboard')">
                <template slot="title">
                  <span class="mrgin-left">进度看板</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="zdrqjkgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">重点人群健康管理</span>
              </template>
              <el-menu-item index="/smartpublicguard/children" @click="saveNavState('/smartpublicguard/children')">
                <template slot="title">
                  <span class="mrgin-left">0-6岁儿童健康管理记录表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/maternal" @click="saveNavState('/smartpublicguard/maternal')">
                <template slot="title">
                  <span class="mrgin-left">孕产妇健康管理记录表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/hypertension"
                @click="saveNavState('/smartpublicguard/hypertension')">
                <template slot="title">
                  <span class="mrgin-left">高血压患者随访记录表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/diabetes" @click="saveNavState('/smartpublicguard/diabetes')">
                <template slot="title">
                  <span class="mrgin-left">糖尿病患者随访记录表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/psychosis" @click="saveNavState('/smartpublicguard/psychosis')">
                <template slot="title">
                  <span class="mrgin-left">严重精神障碍患者管理记录表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/phthisis" @click="saveNavState('/smartpublicguard/phthisis')">
                <template slot="title">
                  <span class="mrgin-left">肺结核患者管理记录表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/chinesemedicine"
                @click="saveNavState('/smartpublicguard/chinesemedicine')">
                <template slot="title">
                  <span class="mrgin-left">中医药健康管理服务记录表</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="ylwsfw">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">医疗卫生服务</span>
              </template>
              <el-menu-item index="/smartpublicguard/consultation"
                @click="saveNavState('/smartpublicguard/consultation')">
                <template slot="title">
                  <span class="mrgin-left">会诊服务</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/referral" @click="saveNavState('/smartpublicguard/referral')">
                <template slot="title">
                  <span class="mrgin-left">转诊服务</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">预防接种</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康教育</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">传染病和突发公共卫生事件管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">免费提供避孕药具</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">卫生计生监督协管</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康素养促进行动管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartpublicguard/ncd" @click="saveNavState('/smartpublicguard/ncd')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">智慧慢病</span>
              </template>
            </el-menu-item>

            <el-submenu index="cxtj">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">查询统计</span>
              </template>
              <el-menu-item index="/smartpublicguard/querystatistics/testanalysis"
                @click="saveNavState('/smartpublicguard/querystatistics/testanalysis')">
                <template slot="title">
                  <span class="mrgin-left">体检分析</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/404">
                <template slot="title">
                  <span class="mrgin-left">机构自查</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="xtgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">系统管理</span>
              </template>
              <el-menu-item index="/smartpublicguard/systemrun/organuserrun"
                @click="saveNavState('/smartpublicguard/systemrun/organuserrun')">
                <template slot="title">
                  <span class="mrgin-left">机构用户管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/organlist"
                @click="saveNavState('/smartpublicguard/systemrun/organlist')">
                <template slot="title">
                  <span class="mrgin-left">机构列表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/menuroleset"
                @click="saveNavState('/smartpublicguard/systemrun/menuroleset')">
                <template slot="title">
                  <span class="mrgin-left">菜单角色设置</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/globalconfig"
                @click="saveNavState('/smartpublicguard/systemrun/globalconfig')">
                <template slot="title">
                  <span class="mrgin-left">全局配置</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/monitoritems"
                @click="saveNavState('/smartpublicguard/systemrun/monitoritems')">
                <template slot="title">
                  <span class="mrgin-left">监测项目范围</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/drugcatalogue"
                @click="saveNavState('/smartpublicguard/systemrun/drugcatalogue')">
                <template slot="title">
                  <span class="mrgin-left">药品目录</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/operationlog"
                @click="saveNavState('/smartpublicguard/systemrun/operationlog')">
                <template slot="title">
                  <span class="mrgin-left">操作日志</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartpublicguard/systemrun/abnormallog"
                @click="saveNavState('/smartpublicguard/systemrun/abnormallog')">
                <template slot="title">
                  <span class="mrgin-left">异常日志</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-menu-item index="/smartpublicguard/auxiliarymonitor" @click="saveNavState('/smartpublicguard/auxiliarymonitor')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">辅助监测</span>
              </template>
            </el-menu-item>

            <!-- <el-menu-item index="/smartpublicguard/maternalrun" @click="saveNavState('/smartpublicguard/maternalrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">孕产妇管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartpublicguard/childrenrun" @click="saveNavState('/smartpublicguard/childrenrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">儿童管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartpublicguard/spiritrun" @click="saveNavState('/smartpublicguard/spiritrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">重性精神病管理</span>
              </template>
            </el-menu-item> -->

          </el-menu>
        </div>
        <!-- <div class="uncollapse" @click="isCollapse = !isCollapse">
          <span>收回</span>
          <i class="el-icon-caret-top"></i>
        </div> -->
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
    <!-- <div class="after" @click="openAfterSales">
      <span>售后</span>
    </div> -->
    <!-- 售后 -->
    <AfterSales v-if="showAfterSales" @close="closeAfterSales"></AfterSales>
  </div>
</template>

<script>
import AfterSales from './afterSales.vue'
export default {
  components: {
    AfterSales
  },
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/smartpublicguard',
      showAfterSales: false
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('smartpublicguardPath') || '/smartpublicguard'
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('smartpublicguardPath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    },
    openAfterSales () {
      this.showAfterSales = true
    },
    closeAfterSales () {
      this.showAfterSales = false
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    width: 276px;
    height: 100%;
    background-color: #006EFF;

    .menu {
      width: 100%;
      height: 100%;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 10px);

      &::-webkit-scrollbar {
        width: 0;
      }

      i {
        color: #fff;
      }

      .el-menu {
        border: none;
        background-color: #006EFF !important;
        color: #fff !important;
      }

      /deep/.el-menu-item {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 !important;
        background-color: #006EFF !important;
        font-size: 16px;
        color: #fff !important;
      }

      /deep/.el-menu-item.is-active {
        color: #25A0FF !important;
      }

      .font {
        color: #fff;
        font-size: 16px;
      }

      // /deep/.el-menu-item:nth-child(n+3) {
      //   height: 100px;
      // }

      // /deep/.el-menu-item:nth-child(7) {
      //   height: 50px;
      // }

      // /deep/.el-submenu:nth-child(3) {
      //   .el-menu-item {
      //     height: 50px !important;
      //   }

      // }

      // /deep/.el-submenu:nth-child(6) {
      //   .el-menu-item {
      //     height: 50px !important;
      //   }

      // }

      /deep/.el-submenu__title {
        display: flex;
        align-items: center;
        height: 45px;
        padding: 1px !important;
        padding-right: 2px !important;
        background-color: #006EFF !important;
        color: #17212B !important;
      }

      /deep/.el-icon-arrow-down:before {
        content: '';
      }

      .menu-text {
        // width: calc(100% - 40px);
        line-height: normal;
        white-space: pre-wrap;
      }

      .submenu-text {
        // width: calc(100% - 30px);
        line-height: normal;
        white-space: pre-wrap;
      }

      .mrgin-left {
        display: block;
        // width: 80px;
        margin-left: 30px;
        line-height: normal;
        white-space: pre-wrap;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 276px);
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .after {
    position: absolute;
    bottom: 50%;
    right: 40px;
    z-index: 7;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    background-color: #3FA1FF;
    color: #DCE4EC;
    font-size: 18px;
    cursor: pointer;
  }
}</style>
